<template>
  <v-menu ref="menuRef" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" min-width="290px" offset-y>
    <template v-slot:activator="{ on }">
      <v-text-field v-on="on" v-bind="$attrs" :value="displayDate" :label="label" :rules="rules" :solo="solo" :hide-details="hideDetails" :dense="dense" readonly outlined>
        <template v-if="clearButton && date" v-slot:append-outer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="date = ''">fal fa-times-circle</v-icon>
            </template>
            Clear Entry
          </v-tooltip>
        </template>
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData"></slot>
        </template>
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName"></slot>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-model="date" ref="pickerRef" :no-title="noTitle" :min="minDate" :max="max" :allowed-dates="allowedDateFunc" :range="dateRange" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="menuRef.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  props: {
    label: {
      type: String,
      default: 'Date'
    },
    isBirthDate: {
      type: Boolean,
      default: false
    },
    yearFirst: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: true
    },
    minDate: String,
    maxDate: String,
    rules: Array,
    clearButton: {
      type: Boolean,
      default: () => false
    },
    dense: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    blockedDates: {
      type: Array,
      default: () => { return [] }
    },
    dateRange: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array]
    }
  },
  setup (props, { emit }) {
    const pickerRef = ref(null)
    const menuRef = ref(null)
    const menu = ref(false)
    const date = ref(props.dateRange ? ['', ''] : '')
    const max = computed(() => {
      if (props.isBirthDate) {
        let dt = new Date()
        let month = dt.getMonth() + 1
        return dt.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + dt.getDate()
      } else if (props.maxDate) {
        return props.maxDate
      }
      return ''
    })
    watch(() => props.value, (val) => {
      date.value = val
    })
    watch(date, (val) => {
      if (val !== props.value) {
        emit('input', val)
        emit('change', val)
      }
    })
    watch(menu, () => {
      menu.value && (props.isBirthDate || props.yearFirst) && setTimeout(() => (pickerRef.value.activePicker = 'YEAR'))
    })

    const displayDate = computed(() => {
      if (props.dateRange && Array.isArray(date.value)) {
        return date.value.join(' - ')
      } else {
        return date.value
      }
    })

    function allowedDateFunc (val) {
      for (const date of props.blockedDates) {
        if (val === date) return false
      }
      return true
    }

    return {
      pickerRef,
      menuRef,
      menu,
      date,
      max,
      displayDate,
      allowedDateFunc
    }
  }
}
</script>
